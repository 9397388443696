<template>
  <div class="downbox">
    <div class="downboxpc">
      <div class="w downboxw">
        <div class="telbox">
          <div style="width: 274px; height: 595px">
            <el-carousel width="274px" height="595px">
              <el-carousel-item v-for="(img, index) in imgs" :key="index">
                <img :src="img" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="contentbox">
          <h3 class="font36500 black2">西语派学道APP</h3>
          <h4 class="font20500 black3">
            「西语派学道」是一套针对中国西班牙语学习者特别设计的教育软件。
          </h4>
          <p class="font20400 black3">
            ·软件以西语词典为基础，涵盖西汉，汉西，西西，经贸，技术，动词搭配等词典<br />
            ·提供动词变位查询功能<br />
            ·词典例句真实、来源丰富；例句均选自西语原版小说，官方媒体文件，真实可靠<br />
            ·具备句对齐的平行语料库功能<br />
            ·APP提供丰富的零基础、DELE、专八、CATTII，商务、少儿西班牙语等课程<br />
            ·大量免费观看的西班牙语影视剧、历史、地理等音视频资料
          </p>
          <div class="codebox">
            <div class="leftbox">
              <a
                href="https://apps.apple.com/cn/app/%E8%A5%BF%E8%AF%AD%E6%B4%BE%E5%AD%A6%E9%81%93-%E8%A5%BF%E7%8F%AD%E7%89%99%E8%AF%AD%E8%AF%8D%E5%85%B8%E5%9C%A8%E7%BA%BF%E5%AD%A6%E4%B9%A0%E5%B7%A5%E5%85%B7/id1635496559"
                target="_blank"
              >
                <img src="./images/appstore.png" alt=""
              /></a>
              <a :href="baseUrl + androdurl" target="_blank"
                ><img src="./images/android.png" alt=""
              /></a>
            </div>
            <div class="rightbox">
              <img src="./images/QRcodeUse.png" alt="" />
              <p class="font16500 black2">
                扫码下载APP<br />各大应用商店均已上线
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="downboxpad">
      <div class="w downboxwpad">
        <div class="contentbox">
          <h3 class="font36500 black2">西语派学道APP</h3>
          <h4 class="font20500 black3">
            「西语派学道」是一套针对中国西班牙语学习者特别设计的教育软件。
          </h4>
          <p class="font20400 black3">
            ·软件以西语词典为基础，涵盖西汉，汉西，西西，经贸，技术，动词搭配等词典<br />
            ·提供动词变位查询功能<br />
            ·词典例句真实、来源丰富；例句均选自西语原版小说，官方媒体文件，真实可靠<br />
            ·具备句对齐的平行语料库功能<br />
            ·APP提供丰富的零基础、DELE、专八、CATTII，商务、少儿西班牙语等课程<br />
            ·大量免费观看的西班牙语影视剧、历史、地理等音视频资料
          </p>
          <div class="codebox">
            <div class="leftbox">
              <a
                href="https://apps.apple.com/cn/app/%E8%A5%BF%E8%AF%AD%E6%B4%BE%E5%AD%A6%E9%81%93-%E8%A5%BF%E7%8F%AD%E7%89%99%E8%AF%AD%E8%AF%8D%E5%85%B8%E5%9C%A8%E7%BA%BF%E5%AD%A6%E4%B9%A0%E5%B7%A5%E5%85%B7/id1635496559"
                target="_blank"
                ><img src="./images/appstore.png" alt=""
              /></a>
              <a :href="baseUrl + androdurl" target="_blank"
                ><img src="./images/android.png" alt=""
              /></a>
            </div>
          </div>
        </div>
        <div class="telbox">
          <div style="width: 193px; height: 422px">
            <el-carousel width="193px" height="422px">
              <el-carousel-item v-for="(img, index) in imgs" :key="index">
                <img :src="img" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="rightbox">
            <img src="./images/QRcodeUse.png" alt="" />
            <p class="font16500 black2">
              扫码下载APP<br />各大应用商店均已上线
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  appConfig
} from "@/api"
export default {
  data () {
    return {
      imgs: [
        require('./images/one.png'),
        require('./images/two.png'),
        require('./images/three.png'),
        require('./images/four.png'),
        require('./images/five.png'),
      ],
      androdurl: "",
      baseUrl: window.__baseUrl__,
    }
  },
  mounted () {
    this.getAppConfig()
  },
  methods: {
    getAppConfig () {
      appConfig().then((d) => {
        console.log(d, 'role')
        if (d.code == 200) {
          this.androdurl = d.data.versionUrl
        }
      })
    }

  }

}
</script>

<style lang="less" scoped>
@import url(../../assets/css/common.css);
.downbox {
  width: 100%;
  padding: 130px 0 120px;
  transition: all 0.5s linear;
  .downboxw {
    // height: 830px;
    opacity: 1;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.6);
    border: 2px solid rgba(255, 255, 255, 1);
    backdrop-filter: blur(20px);
    // background-image: url("./images/iPhone.png");
    // background-size: 796px 796px;
    // background-position: left bottom;

    // background-repeat: no-repeat;

    display: flex;

    .telbox {
      width: 796px;
      height: 796px;
      // padding-right: 40px;
      position: relative;
      padding-left: 308px;
      padding-top: 80px;
    }
    .telbox::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url("./images/iPhone1.png");
      background-size: 796px 796px;
      background-position: left bottom;

      background-repeat: no-repeat;
      z-index: 9;
    }
    .contentbox {
      width: 54%;
      // margin-left: 688px;
      padding: 90px 20px 20px 0;
      text-align: justify;

      h4 {
        line-height: 3;
      }
      p {
        line-height: 1.7;
      }
      .codebox {
        display: flex;
        .leftbox {
          display: flex;
          flex-direction: column;
          margin-right: 118px;
          img {
            width: 204px;
            height: 60px;
            margin-top: 40px;
          }
        }
        .rightbox {
          img {
            width: 120px;
            height: 120px;
          }

          margin-top: 40px;
          text-align: center;
        }
      }
    }
  }
  .downboxwpad {
    // height: 830px;
    opacity: 1;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.6);
    border: 2px solid rgba(255, 255, 255, 1);
    backdrop-filter: blur(20px);
    // background-image: url("./images/iPhone.png");
    // background-size: 796px 796px;
    // background-position: left bottom;

    // background-repeat: no-repeat;

    display: flex;
    flex-direction: column;

    .telbox {
      // width: 796px;
      // height: 796px;
      width: 100%;
      height: 565px;
      // padding-right: 40px;
      position: relative;
      padding-left: 220px;
      padding-top: 60px;
      padding-right: 160px;
      display: flex;
      justify-content: space-between;
      .rightbox {
        img {
          width: 120px;
          height: 120px;
        }

        margin-top: 40px;
        text-align: center;
      }
    }
    .telbox::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url("./images/iPhone1.png");
      background-size: 565px 565px;
      background-position: left bottom;

      background-repeat: no-repeat;
      z-index: 9;
    }
    .contentbox {
      width: 100%;
      // margin-left: 688px;
      padding: 50px;
      text-align: justify;
      h3 {
        text-align: center;
      }
      h4 {
        line-height: 3;
        text-align: center;
      }
      p {
        line-height: 1.7;
      }
      .codebox {
        // display: flex;
        .leftbox {
          display: flex;
          // flex-direction: column;
          // margin-right: 118px;
          justify-content: space-around;
          img {
            width: 204px;
            height: 60px;
            margin-top: 40px;
          }
        }
      }
    }
  }
}
.downboxpad {
  display: none;
}
@media (max-width: 1300px) {
  .downboxpc {
    display: none !important;
  }
  .downboxpad {
    display: block !important;
  }
}
@media (max-width: 900px) {
  .downboxwpad {
    .telbox {
      padding-right: 30px !important;
    }
  }
}
</style>